<template>
<v-card flat>
<v-btn color="primary" outlined width="100%" rounded x-large  class="mt-5 pa-5" target="blank" dark :href="$apiPath + 'assets/' + $store.state.eventInfo.EventUrl + ($store.state.eventInfo.UType=='SubAdmin'? '/SubAdmin-Manual.pdf':'/Admin-Manual.pdf')">
  Admin Manual
  <v-icon right>mdi-download</v-icon>
</v-btn>

<v-btn color="primary" outlined width="100%" rounded x-large  class="mt-5 pa-5" target="blank" dark :href="$apiPath + 'assets/' + $store.state.eventInfo.EventUrl + '/Application-Manual.pdf'">
  Applicant Manual
  <v-icon right>mdi-download</v-icon>
</v-btn>
</v-card>
 
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        color: "white darken-2",
        icon: "mdi-star",
        text: "Admin Manual Download",
      },
      {
        color: "grey darken-2",
        icon: "mdi-book-variant",
        text: "Application Manual Download",
      },

    ],
  }),
};
</script>