<template>
  <div class="pa-4">
   
    <v-row>
      <v-col cols="12" md="6" class="pa-1">
        <v-card flat height="300px">
          <v-card-text>Applications Assigned</v-card-text>
          <v-divider></v-divider>
          <Assigned
            v-if="dData.application_Summary"
            :cdata="dData.application_Summary"
          ></Assigned>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="pa-1">
        <v-card flat height="300px">
          <v-card-text>Applications Received</v-card-text>
          <v-divider></v-divider>
          <Submitted
            v-if="dData.application_Status"
            :cdata="dData.application_Status"
          ></Submitted>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="pa-1">
        <v-card flat height="330px" v-if="dData.venue_dtl">
         <v-card-text>{{dData.venue_dtl.stadium_name}}</v-card-text>
          <v-divider></v-divider>
          <div class="text-center">           
              <iframe
              :src="dData.venue_dtl.google_location"
              width="98%"
              height="265"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>          
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="pa-1">
        <v-card flat height="330px">
          <v-card-text>Information</v-card-text>
          <v-divider></v-divider>
          <v-card-text class="py-0">
            <Howtoapply></Howtoapply>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="isLoading" style="z-index: 9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import axios from "axios";
//import Countdown from "../../components/dashboard/countdown";
import Howtoapply from "../../components/Admin/dashboard/howtoapply";
import Assigned from "../../components/Admin/dashboard/assigned";
import Submitted from "../../components/Admin/dashboard/submitted";
import "viewerjs/dist/viewer.css";

export default {
  components: {  Howtoapply, Assigned, Submitted },
  data() {
    return {
      dData: {},
      isLoading: false,
    };
  },
   mounted() {
    this.$store.commit("setPageTitle", "Dashboard");
    this.$store.commit("setPageTitleIcon", "mdi-apps");
    this.isLoading = false;
     this.$axios
      .get("EventDashBoard/" +
          this.$store.state.eventInfo.EventId +
          "/" +
          this.$store.state.eventInfo.VenueId +  "/" + this.$store.state.eventInfo.EventAdminID)
      .then((res) => {
        this.dData = res.data.result;
        this.isLoading = false;
      })
      .catch(function () {
        this.isLoading = false;
      });
  },
}; //2020-10-28 12:20:00
</script>

<style>
</style>

